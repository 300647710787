import request from '@/utils/request'

const creditLetterRepaymentApi = {
  trade_credit_letter_repayment_list: '/trade/credit_letter_repayment/',
  trade_credit_letter_repayment_create: '/trade/credit_letter_repayment/',
  trade_credit_letter_repayment_update: '/trade/credit_letter_repayment/',
  trade_credit_letter_repayment_delete: '/trade/credit_letter_repayment/',
  trade_credit_letter_repayment_archived: '/trade/credit_letter_repayment_archived/',
  credit_letter_repayment_bill_negotiation: '/trade/credit_letter_repayment_bill_negotiation/'
}

/**
 * 列表
 */
export function credit_letter_repayment_list (parameter) {
  return request({
    url: creditLetterRepaymentApi.trade_credit_letter_repayment_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function credit_letter_repayment_create (parameter) {
  return request({
    url: creditLetterRepaymentApi.trade_credit_letter_repayment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function credit_letter_repayment_update (parameter, corporation_id) {
  return request({
    url: creditLetterRepaymentApi.trade_credit_letter_repayment_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function credit_letter_repayment_delete (corporation_id) {
  return request({
    url: creditLetterRepaymentApi.trade_credit_letter_repayment_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function credit_letter_repayment_archived (parameter) {
  return request({
    url: creditLetterRepaymentApi.trade_credit_letter_repayment_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 列表
 */
export function credit_letter_repayment_bill_negotiation_list (parameter) {
  return request({
    url: creditLetterRepaymentApi.credit_letter_repayment_bill_negotiation,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function credit_letter_repayment_bill_negotiation_create (parameter) {
  return request({
    url: creditLetterRepaymentApi.credit_letter_repayment_bill_negotiation,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function credit_letter_repayment_bill_negotiation_delete (record_id) {
  return request({
    url: creditLetterRepaymentApi.credit_letter_repayment_bill_negotiation + record_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
