import request from '@/utils/request'

const tradeBankApi = {
  trade_bank_list: '/trade/bank/',
  trade_bank_create: '/trade/bank/',
  trade_bank_update: '/trade/bank/',
  trade_bank_delete: '/trade/bank/'
}

/**
 * 列表
 */
export function bank_list (parameter) {
  return request({
    url: tradeBankApi.trade_bank_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bank_create (parameter) {
  return request({
    url: tradeBankApi.trade_bank_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bank_update (parameter, bank_id) {
  return request({
    url: tradeBankApi.trade_bank_update + bank_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bank_delete (bank_id) {
  return request({
    url: tradeBankApi.trade_bank_delete + bank_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
